import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import SummaryTable from "components/SummaryTable";
import { useWizard } from "react-use-wizard";
import { useCheckoutContext } from "context/CheckoutContext";
import { FloatingLabel } from "react-bootstrap";

const Subscription = ({ cart }) => {
  const { nextStep } = useWizard();
  const { checkout, setCheckout } = useCheckoutContext();

  // Calculate dates once when component mounts
  const getDefaultDates = () => {
    const currentDate = new Date();

    // First, create a date 30 days from now
    const minDate = new Date(currentDate);
    minDate.setDate(currentDate.getDate() + 30);

    // Get the initial calculated date parts
    let targetYear = minDate.getFullYear();
    let targetMonth = minDate.getMonth();
    let targetDay = minDate.getDate();

    // If we've crossed into March (or any month after the next month),
    // pull back to 27th of the previous month
    const startMonth = currentDate.getMonth();
    const calculatedMonth = targetMonth;

    if (calculatedMonth > ((startMonth + 1) % 12)) {
      targetMonth = (startMonth + 1) % 12;
      targetDay = 27;
      if (targetMonth === 0) {
        targetYear = currentDate.getFullYear() + 1;
      }
    } else if (targetDay >= 28) {
      targetDay = 27;
    }

    const minDateString = `${targetYear}-${String(targetMonth + 1).padStart(2, '0')}-${String(targetDay).padStart(2, '0')}`;

    // For max date, add 30 days to the min date
    const maxDate = new Date(targetYear, targetMonth, targetDay);
    maxDate.setDate(maxDate.getDate() + 30);

    let maxYear = maxDate.getFullYear();
    let maxMonth = maxDate.getMonth();
    let maxDay = maxDate.getDate();

    if (maxDay >= 28) {
      maxDay = 27;
    }

    const maxDateString = `${maxYear}-${String(maxMonth + 1).padStart(2, '0')}-${String(maxDay).padStart(2, '0')}`;

    return { minDateString, maxDateString };
  };

  // Get dates once and memoize them to avoid re-calculating them on every render
  const { minDateString, maxDateString } = React.useMemo(
    () => getDefaultDates(),
    []
  );

  useEffect(() => {
    // Initialize subscription with default values
    setCheckout({
      ...checkout,
      currentStep: 2,
      subscription: {
        ...checkout.subscription,
        frequency: "Monthly",
        startDate: minDateString,
      },
    });
  }, [minDateString]); // Add minDateString as dependency

  const {
    subscription: { frequency, startDate },
  } = checkout;

  const handleSubmit = (event) => {
    event.preventDefault();

    // Proceed to the next step
    nextStep();
  };

  const updateContext = (target, value) => {
    let subscriptionForm = checkout.subscription;
    subscriptionForm[target] = value;

    setCheckout({ ...checkout, subscription: subscriptionForm });
  };

  return (
    <div>
      <div className="wizardContainer">
        <button className="wizardLink">Cart</button>
        {">"}
        <button className="wizardLink">Account Details</button>
        {">"}
        <button className="wizardLink">Shipping Address</button>
        {">"}
        <button className="wizardLink">Subscription</button>
        {">"}
        <button className="wizardLink" disabled>
          Payment
        </button>
      </div>

      <SummaryTable
        contact={cart.customer.email}
        referral={cart.cart.referral}
      />

      <Row className="mb-3">
        <Col md="12">
          <h2 className="marginBottomSmall marginTopSmall">Subscription</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <FloatingLabel
                controlId="floatingSelect"
                label="How often do you want to receive this?"
                className="mb-3"
              >
                <Form.Select
                  value={frequency}
                  onChange={(e) => updateContext("frequency", e.target.value)}
                  required
                  disabled
                >
                  <option value="Monthly">Monthly</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3">
              <FloatingLabel
                controlId="floatingSelect"
                label="When should it start?"
                className="mb-3"
              >
                <Form.Control
                  type="date"
                  value={startDate || minDateString}
                  onChange={(e) => {
                    const selectedDate = new Date(e.target.value);
                    let finalDate = e.target.value;

                    // If selected date is 28th or later, set to 27th of the same month
                    if (selectedDate.getDate() >= 28) {
                      const adjustedDate = new Date(selectedDate);
                      adjustedDate.setDate(27);
                      finalDate = `${adjustedDate.getFullYear()}-${String(adjustedDate.getMonth() + 1).padStart(2, '0')}-27`;
                    }

                    updateContext("startDate", finalDate);
                  }}
                  min={minDateString}
                  max={maxDateString}
                  required
                />
              </FloatingLabel>
              <Form.Text className="text-muted">
                For optimal processing, auto-orders after the 27th will be scheduled for the 27th of the following month.
              </Form.Text>
            </Form.Group>

            <div className="flex JCEnd">
              <Button
                className="btn-bold oola-main-color"
                variant="primary"
                type="submit"
              >
                Continue
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Subscription;
